/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const getSchemaPersonnJSONLD = ({
  authorName,
  authordescription,
  authorimage,
  authorurl,
  authorSocial
}) => ({
  "@context": "http://schema.org",
  "@type": "Person",
  "name": authorName,
  "description": authordescription,
  "image": authorimage,
  "url": authorurl,
  "sameAs": authorSocial
})

function SEONOINDEX({ canonical, description, lang, meta, title,authorName, authordescription,
  authorimage,
  authorurl,
  authorSocial }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const schemaOrgJSONLD = authorName ? getSchemaPersonnJSONLD({
    authorName,
    authordescription,
    authorimage,
    authorurl,
    authorSocial
})
    : "";
  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
    defer={false}
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s`}
      link={
        canonical
        ? [{ rel: 'canonical', key: canonical, href: canonical }]
        : []
        }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `robots`,
          content: 'noindex,follow',
        }
      ].concat(meta)}
      script={[
        {
            type: 'application/ld+json',
            innerHTML: `${JSON.stringify(schemaOrgJSONLD)}`
        }
    ]}
    />
  )
}

SEONOINDEX.defaultProps = {
  lang: `th-TH`,
  meta: [],
  description: ``,
}

SEONOINDEX.propTypes = {
  canonical: PropTypes.string,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEONOINDEX